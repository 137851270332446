import {DELETE, GET, POST, PUT} from './HTTP';
import { Journey } from "@/models/JourneyModel";
import {Resource} from "@/models/ResourceModel";

export function updateJourneys(journeys: Journey[]): Promise<any> {
    return PUT('/journeys', journeys);
}

export function updateJourney(journey: Journey): Promise<any> {
    return PUT(`/journeys/${journey.id}`, journey);
}

export function deleteJourney(journeyId: string): Promise<any> {
    return DELETE(`/journeys/${journeyId}`);
}

export function createJourney(data: any): Promise<any> {
    return POST(`/journeys`, data);
}

export async function getJourney(journeyId: string): Promise<any> {
    return GET(`/journeys/${journeyId}`);
}

export async function getJourneyTabResources(journeyId: string, tabNum: number): Promise<Array<string>> {
    return GET(`/journeys/${journeyId}/tabs/${tabNum}/resources`);
}

export async function addJourneyTabResource(journeyId: string, tabNum: number, resource: Resource): Promise<any> {
    return PUT(`/journeys/${journeyId}/tabs/${tabNum}`, resource);
}

export async function removeJourneyTabResource(journeyId: string, tabNum: number, resourceUUID: string): Promise<any> {
    return DELETE(`/journeys/${journeyId}/tabs/${tabNum}/resources/${resourceUUID}`);
}
